import i18n from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
    en: {
        translation: {

        }
    },
    zh_CH: {
        translation: {

        }
    },
    zh_CN: {
        translation: {

        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem('lang') || 'zh_CN', //设置当前语言

        keySeparator: false, // we do noat use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
