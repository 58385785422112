import './index.less'
import {useEffect, useState} from 'react'
// import { eventBus } from '@/utils/emitter'
import LoadingSvg from './img/loading.svg'

function Loading() {
    const [visible, setVisible] = useState(false)
    const [text, setText] = useState<string|undefined>('正在拼命加载...')
    useEffect(() => {
        // eventBus.$on('loading', (val:boolean, text?:string) => {
        //     if(text !== null){
        //         setText(text)
        //     }
        //     if(!val){
        //         setText('')
        //     }
        //     setVisible(val)
        // })
    }, [])
    return (
        <div>
            {
                visible ? (
                    <div className="loading-bg">
                        <div className="loading-container">
                            {/*<img  src={require('./img/loading.gif')} alt=""/>*/}
                            <div className="text">{text}</div>
                        </div>
                    </div>
                ) : null
            }
        </div>
    )
}

export default Loading
