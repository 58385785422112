import { Routes, Route } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import Loading from '../components/loading';
// import {Toast} from "react-vant";
const RoomShare = lazy(() => import('../pages/RoomShare/index'));
const Follow = lazy(() => import('../pages/Follow/index'));

const Routers = () => {
  return (
    // 没有loading的话就去掉
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/room/share" element={<RoomShare />} />
        <Route path="/followus" element={<Follow />} />
      </Routes>
    </Suspense>
  );
};

export default Routers;
