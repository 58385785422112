import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom/client';
// import {Provider} from 'react-redux'
// import store from './store'
import 'normalize.css';
import 'react-vant/es/styles';
import './index.less';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import AppLayout from '@/layout/appLayout';
import { Toast } from 'react-vant';
import './langs';

if (process.env.REACT_APP_CONFIG_ENV !== 'production') {
  import('vconsole').then((Vconsole) => {
    new Vconsole.default();
    console.log('current url: ', window.location.href);
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
window.REACT_APP_REQUEST_URL = '';

root.render(
  // <Provider store={store}>
  // <Provider>
  <HashRouter>
    <AppLayout />
  </HashRouter>
  // </Provider>
);
