import Router from '../router/app';

function AppLayout(){
    return (
        <div>
            <Router/>
        </div>
    )
}

export default AppLayout
